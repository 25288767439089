<template>
  <div>
    <div
      v-if="_visualMode === 'textWithChipB'"
      class="d-flex flex-row align-center"
    >
      <div v-if="status.id !== 1 && status.id !== 3">
        <span class="mr-1">{{$t('t.Installment')}}</span>
        <span class="mr-1">{{indexAndCountText}}</span>
      </div>

      <v-chip
        class="mr-1"
        v-if="status.id !== 0"
        :color="statusColor"
        :small="smallChip"
        :large="largeChip"
        :outlined="outlinedChip"
      >
        <span>{{statusText}}</span>
      </v-chip>
      <div v-else>
        <span class="mr-1">{{statusText}}</span>
      </div>
      <span
        class="mr-1"
        v-if="calendarLinkText"
      >{{calendarLinkText}}</span>
      <calendar-date
        v-if="status.installment.date"
        :date="status.installment.date"
        :lowerCase="true"
      />
    </div>
    <div v-if="_visualMode === 'textWithChipA'">
      <v-chip
        v-if="status.id !== 1 && status.id !== 3"
        :color="statusColor"
        :small="smallChip"
        :large="largeChip"
        :outlined="outlinedChip"
      >
        <!-- {{status.installment}} -->
        <span
          class="mr-1"
          v-if="status.id === 2"
        >{{$t('t.Installment')}}</span>

        <span class="mr-1">{{indexAndCountText}}</span>
        <span class="mr-1">{{statusText}}</span>
        <span
          class="mr-1"
          v-if="calendarLinkText"
        >{{calendarLinkText}}</span>
        <calendar-date
          v-if="status.installment.date"
          :date="status.installment.date"
          :lowerCase="true"
        />

      </v-chip>
      <div v-else>
        <span class="mr-1">{{$t('t.Installment')}}</span>
        <span class="mr-1">{{indexAndCountText}}</span>
        <span class="mr-1">{{statusText}}</span>
        <span
          class="mr-1"
          v-if="calendarLinkText"
        >{{calendarLinkText}}</span>
        <calendar-date
          v-if="status.installment.date"
          :date="status.installment.date"
          :lowerCase="true"
        />
      </div>
    </div>
    <div
      class="d-flex flex-row"
      v-if="_visualMode === 'visual'"
    >
      <v-icon
        v-if="status.id === 1"
        :color="statusColor"
      >{{$icon('i.ThumbUp')}}</v-icon>
      <div v-else>
        <span
          v-for="(_, i) in status.installment.installmentCount"
          :key="i"
        >
          <v-icon
            class="mr-1"
            :size="iconSize(_)"
            :color="iconColor(_, status.id)"
          >{{iconName(_, status.is)}}</v-icon>
        </span>
      </div>

      <calendar-date
        class="ml-2"
        :date="status.installment.date"
        :lowerCase="true"
        v-if="status.installment.date"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date')
  },
  computed: {
    _visualMode () {
      const r = this.visualMode == null
        ? 'textWithChipB'
        : this.visualMode
      return r
    },
    statusColor () {
      switch (this.status.id) {
        case 3:
          return 'warning'
        case 2:
          return 'red'
        case 1:
          return 'green'
        default:
          return 'transparent'
      }
    },
    indexAndCountText () {
      if (this.status.id === 1 || this.status.id === 3) {
        return ''
      }

      return `${this.status.installment.indexOrder + 1}/${this.status.installment.installmentCount}`
    },
    statusText () {
      let r = ''

      if (this.status.id === 2) {
        return `${this.$t('t.Is').toLowerCase()} ${this.status.text.toLowerCase()}`
      }

      if (this.status.id === 0) {
        return `${this.$t('t.Due').toLowerCase()}`
      }

      if (this.status.id === 1 || this.status.id === 3) {
        r = `${this.status.text}`
      }

      return r
    },
    calendarLinkText () {
      const r = null

      const dateForDiff = this.moment(this.status.installment.date)
      const days = Math.abs(this.moment().startOf('day').diff(dateForDiff.startOf('day'), 'days'))

      if ((this.status.id === 2 || this.status.id === 0 || this.status.id === 1) && days > 1) {
        return `${this.$t('t.At').toLowerCase()}`
      }

      return r
    }
  },
  data () {
    return {
    }
  },
  methods: {
    iconSize (idx) {
      const defaultSize = 14
      return idx === this.status.installment.indexOrder + 1 ? 24 : defaultSize
    },
    iconColor (idx, status) {
      const i = this.status.installment.indexOrder + 1
      return idx === i && status === 2
        ? 'error'
        : idx === i && status === 0
          ? 'warning'
          : idx < i
            ? 'success'
            : 'silver'
    },
    iconName (idx, status) {
      const brokenIcon = this.$icon('i.AlertCircleOutline')
      const heldIcon = this.$icon('i.Checked')
      const futurIcon = this.$icon('i.UncheckedCircle')
      const currentIcon = this.$icon('i.Target')
      const i = this.status.installment.indexOrder + 1

      return idx === i && status === 2
        ? brokenIcon
        : idx === i && status === 0
          ? currentIcon
          : idx < i
            ? heldIcon
            : futurIcon
    }
  },
  props: {
    status: Object,
    smallChip: Boolean,
    largeChip: Boolean,
    outlinedChip: Boolean,
    visualMode: String // textWithChipA, textWithChipB, visual
  }
}
</script>

<style lang="stylus" scoped></style>
